import locales, { DEFAULT_FULL_ISO_CODE, DEFAULT_ISO_CODE, IsoCode2 } from './locales'
import routes, { links } from './routes'
import { LanguageProps } from '../_crema/@crema/core/LanguageSwitcher/data'
import defaultConfig from '../_crema/@crema/utility/ContextProvider/defaultConfig'
import brandConfig from './brandConfig'
import { FooterType, HeaderType, LayoutType, NavStyle, RouteTransition, ThemeMode, ThemeStyle } from '../_crema/shared/constants/AppEnums'
import AppContextPropsType from '../_crema/types/AppContextPropsType'
import theme from '../components/App/styles/theme_jezykowyguru'

const override = Object.freeze({
  theme,
  routes,
  links,
  headerMode: HeaderType.DARK,
  themeStyle: ThemeStyle.MODERN,
  themeMode: ThemeMode.JEZYKOWY_GURU,
  navStyle: NavStyle.DEFAULT,
  layoutType: LayoutType.BOXED,
  footerType: FooterType.FLUID,
  rtAnim: RouteTransition.NONE,
  footer: true,
  initialPath: '/',
  defaultLocale: DEFAULT_ISO_CODE,
  locale: {
    languageId: locales[DEFAULT_FULL_ISO_CODE].name.toLowerCase(),
    locale: locales[DEFAULT_FULL_ISO_CODE].isoCode,
    isoCode: locales[DEFAULT_FULL_ISO_CODE].fullIsoCode,
    name: locales[DEFAULT_FULL_ISO_CODE].name,
    icon: locales[DEFAULT_FULL_ISO_CODE].flag,
  },
  changeLocale: (undefined as unknown) as (locale: LanguageProps) => void,
  isRTL: theme.direction === 'rtl',
  setRTL: (undefined as unknown) as (rtl: boolean) => void,
  updateTheme: (undefined as unknown) as (theme: any) => void,
  updateLayoutStyle: (undefined as unknown) as (layoutType: LayoutType) => void,
  brand: 'jezykowyguru',
})

export type Type = AppContextPropsType &
  typeof override & {
    defaultLocale: IsoCode2
  } & typeof brandConfig

const app: Type = Object.freeze({
  ...defaultConfig,
  ...override,
  ...brandConfig,
})

export default app
