import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import useStyles from './AppHeader.style'
import LanguageSwitcher from '../../../../_crema/@crema/core/LanguageSwitcher'
import Notifications from '../../../../_crema/@crema/core/Notifications'
import { toggleNavCollapsedAction } from '../../../../services/settings/settings.actions'
import AppLogo from '../shared/AppLogo'
import clsx from 'clsx'
import AppContext from '../../../../contexts/AppContext'

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const { themeMode } = useContext(AppContext)
  const classes = useStyles({ themeMode })
  const dispatch = useDispatch()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={classes.menuItemRoot}></MenuItem>
      <MenuItem className={classes.menuItemRoot}>
        <Notifications />
      </MenuItem>
      <LanguageSwitcher />
    </Menu>
  )

  return (
    <>
      <AppBar className={clsx(classes.headerBgColor, 'app-bar')} color="inherit">
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleNavCollapsedAction())}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <AppLogo />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  )
}

export default AppHeader
