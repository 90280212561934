import _ from 'lodash'
import { Action, ActionTypes, State } from './auth.types'

const initialState: Readonly<State> = {}

const auth = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Action.SIGN_IN:
    case Action.SIGN_UP:
    case Action.UPDATE_TOKEN:
    case Action.CHANGE_PASSWORD:
      return {
        ..._.cloneDeep(state),
        token: action.data.token,
        user: action.data.user,
      }

    case Action.SIGN_UP_ACTIVATION:
      return {
        ..._.cloneDeep(state),
        token: action.data.token,
        user: action.data.user,
        smsCode: action.data.smsCode,
      }

    case Action.SIGN_OUT:
    case Action.FORGOT_PASSWORD:
      return {
        ..._.cloneDeep(state),
        token: undefined,
        user: undefined,
      }

    default:
      return state
  }
}

export default auth
