import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import locales from '../../../config/locales'
import { AppState } from '../../../services/reducers'
import Children from '../../../types/Children'

type Props = {
  children: Children
}

const LocaleProvider = (props: Props) => {
  const { defaultLocale, language } = useSelector<AppState, AppState['settings']>(({ settings }) => settings)

  return (
    <IntlProvider locale={language.isoCode} defaultLocale={defaultLocale} messages={locales[language.fullIsoCode].messages}>
      {props.children}
    </IntlProvider>
  )
}

export default LocaleProvider
