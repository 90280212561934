import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './AppSidebar.style'
import Scrollbar from '../../../../_crema/@crema/core/Scrollbar'
import { ThemeStyle } from '../../../../_crema/shared/constants/AppEnums'
import getMainManu from '../../../../config/menu/getMainManu'
import AppContext, { Type as AppContextType } from '../../../../contexts/AppContext'
import useAuth from '../../../../hooks/useAuth'
import { AppState } from '../../../../services/reducers'
import { toggleNavCollapsedAction } from '../../../../services/settings/settings.actions'
import Navigation from '../shared/Navigation/VerticleNav'
import UserInfo from '../shared/UserInfo'

interface AppSidebarProps {
  variant?: string
  position?: 'left' | 'bottom' | 'right' | 'top'
}

const AppSidebar: React.FC<AppSidebarProps> = ({ position = 'left', variant = '' }) => {
  const { brand, themeStyle, themeMode } = useContext<AppContextType>(AppContext)
  const { user } = useAuth()

  const dispatch = useDispatch()
  const { navCollapsed } = useSelector<AppState, AppState['settings']>(({ settings }) => settings)

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsedAction())
  }

  const classes = useStyles({ themeStyle, themeMode })
  let sidebarClasses = classes.sidebarModern
  if (themeStyle === ThemeStyle.STANDARD) {
    sidebarClasses = classes.sidebarStandard
  }

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={position}
          open={navCollapsed}
          onClose={() => handleToggleDrawer()}
          classes={{
            paper: clsx(variant),
          }}
          style={{ position: 'absolute' }}
        >
          <Box height="100%" className={classes.drawerContainer}>
            <Box height="100%" width="100%" color="primary.contrastText" className={classes.sidebarBg}>
              <UserInfo />
              <Scrollbar className={classes.drawerScrollAppSidebar}>
                <Navigation menu={getMainManu(user, brand)} />
              </Scrollbar>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height="100%" className={classes.container}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <UserInfo />
            <Scrollbar className={clsx(classes.scrollAppSidebar, 'scrollAppSidebar')}>
              <Navigation menu={getMainManu(user, brand)} />
            </Scrollbar>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default AppSidebar
