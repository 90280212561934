import { Schema as BaseSchema, StringSchema } from 'yup'
import * as rule from './user.rules'
import yup from '../../helper/yup'

export interface UserSchema extends BaseSchema<any> {
  id(): StringSchema
  email(): StringSchema
  password(): StringSchema
  oldPassword(): StringSchema
  newPassword(): StringSchema
  repeatedPassword(): StringSchema
  firstName(): StringSchema
  phone(): StringSchema
}

export class Schema extends yup.mixed implements UserSchema {
  id = () => yup.string().uuid()
  email = () => yup.string().email('Wprowadź poprawny adres e-mail').trim()
  password = () => yup.string().min(rule.PASSWORD_MIN_LEGTH, `Minimalna długość pola to ${rule.PASSWORD_MIN_LEGTH} znaków`).trim()
  oldPassword = () => yup.string().min(rule.PASSWORD_MIN_LEGTH, `Minimalna długość pola to ${rule.PASSWORD_MIN_LEGTH} znaków`).trim()
  newPassword = () => yup.string().min(rule.PASSWORD_MIN_LEGTH, `Minimalna długość pola to ${rule.PASSWORD_MIN_LEGTH} znaków`).trim()
  repeatedPassword = () =>
    yup
      .string()
      .min(rule.PASSWORD_MIN_LEGTH)
      .test('match', 'Wpisz takie samo hasło jak w polu powyżej', function () {
        return this.parent.newPassword === this.parent.repeatedPassword
      })
      .trim()
  firstName = () => yup.string().min(rule.FIRST_NAME_MIN_LEGTH, `Minimalna długość pola to ${rule.FIRST_NAME_MIN_LEGTH} znaki`).trim()
  surname = () => yup.string().min(rule.SURNAME_MIN_LEGTH).trim()
  phone = () => yup.string().trim()
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Schema()
