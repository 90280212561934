import { Schema as BaseSchema, StringSchema } from 'yup'
import * as rule from './course.rules'
import yup from '../../helper/yup'

export interface UserSchema extends BaseSchema<any> {
  id(): StringSchema
  code(): StringSchema
}

export class Schema extends yup.mixed implements UserSchema {
  id = () => yup.string().uuid()
  code = () =>
    yup
      .string()
      .min(rule.CODE_MIN_LEGTH, `Kod aktywacyjny ma ${rule.CODE_MIN_LEGTH} znaków`)
      .max(rule.CODE_MAX_LEGTH, `Kod aktywacyjny ma ${rule.CODE_MAX_LEGTH} znaków`)
      .trim()
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Schema()
