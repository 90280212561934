import { ListItem } from '@material-ui/core'
import clsx from 'clsx'
import React, { useContext, useMemo } from 'react'
import VerticalCollapse from './VerticalCollapse'
import VerticalItem from './VerticalItem'
import useStyles from './VerticalNavGroup.style'
import IntlMessages from '../../../../../../_crema/@crema/utility/IntlMessages'
import { NavItemProps } from '../../../../../../_crema/modules/routesConfig'
import AppContext from '../../../../../../contexts/AppContext'
import useAuth from '../../../../../../hooks/useAuth'
import { hasAccess } from '../../../../../../types/User'

interface VerticalNavGroupProps {
  item: NavItemProps
  level: number
}

const VerticalNavGroup: React.FC<VerticalNavGroupProps> = ({ item, level }) => {
  const { themeMode } = useContext(AppContext)
  const classes = useStyles({ level, themeMode })
  const { user } = useAuth()
  const hasPermission = useMemo(() => hasAccess(user, item.auth), [item.auth, user])

  if (!hasPermission) {
    return null
  }

  return (
    <>
      <ListItem component="li" className={clsx(classes.navItem, 'nav-item nav-item-header')}>
        {<IntlMessages id={item.messageId} />}
      </ListItem>

      {item.children && Array.isArray(item.children) && (
        <>
          {item.children.map((item: any) => (
            <React.Fragment key={item.id}>
              {item.type === 'group' && <NavVerticalGroup item={item} level={level} />}

              {item.type === 'collapse' && <VerticalCollapse item={item} level={level} />}

              {item.type === 'item' && <VerticalItem item={item} level={level} />}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  )
}

const NavVerticalGroup = VerticalNavGroup

export default NavVerticalGroup
