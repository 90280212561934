import React, { useEffect } from 'react'
import { matchRoutes } from 'react-router-config'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../../../_crema/@crema/core/Loader'
import routes, { links } from '../../../config/routes'
import useAuth from '../../../hooks/useAuth'
import Children from '../../../types/Children'
import { Route } from '../../../types/Routes'
import { hasAccess } from '../../../types/User'

type Props = {
  children: Children
}

const AuthRouter = (props: Props) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { /*isSignedIn,*/ user, loading } = useAuth()

  const matchedCurrentRoutes = matchRoutes(routes, pathname)
  const currentRoute = matchedCurrentRoutes && matchedCurrentRoutes.length > 0 ? (matchedCurrentRoutes[0].route as Route) : undefined
  const isPermitted = hasAccess(user, currentRoute?.roles)

  useEffect(() => {
    if (!loading) {
      if (!user && !isPermitted) {
        history.push(links.authSignIn.path) // allowed route
      } else if (user && !isPermitted) {
        if (currentRoute?.path === '/auth/sign-in') {
          history.push(links.userLanguages.path)
        } else {
          history.push(links.error403.path) // Not found
        }
      } else if (user && !user.active) {
        history.push(links.authChangePassword.path)
      }
    }
  }, [user, loading, isPermitted, pathname, history, currentRoute?.path])

  return loading ? <Loader /> : <>{props.children}</>
}

export default AuthRouter
