import { TestContext, ValidationError } from 'yup'

const createError = (message: string, context: TestContext): ValidationError => {
  const { path, createError: create } = context
  return create({
    path,
    message,
  })
}

export default createError
