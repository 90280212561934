import React from 'react'
import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import reportWebVitals from './reportWebVitals'
import '../../_crema/shared/styles/index.css'
import App from '../../components/App'

ReactGA.initialize('UA-61286389-46')
ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
