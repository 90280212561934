import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeMode } from '../../../../../_crema/shared/constants/AppEnums'
import { links } from '../../../../../config/routes'
import AppContext, { Type as AppContextType } from '../../../../../contexts/AppContext'
import { BrandLogo } from '../../../../../assets/images/logos'

const useStyles = makeStyles(() => ({
  logoRoot: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  logo: {
    height: 80,
    marginRight: 10,
  },
}))

type Props = {
  mode?: 'light' | 'dark'
}

const AppLogo = (props: Props) => {
  const history = useHistory()
  const { brand, themeMode } = useContext<AppContextType>(AppContext)
  const classes = useStyles()

  const handleLogoClick = () => {
    history.push(links.userLanguages.path)
  }

  return (
    <Box className={classes.logoRoot}>
      <img
        onClick={handleLogoClick}
        className={classes.logo}
        src={
          themeMode === ThemeMode.DARK || props.mode === 'dark' || themeMode === ThemeMode.SPEAK_KING
            ? BrandLogo[brand].white
            : BrandLogo[brand].normal
        }
        alt={brand + '-logo'}
      />
    </Box>
  )
}

export default AppLogo
