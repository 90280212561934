import { v4 as createUuid } from 'uuid'
import { Action, CreateMessageAction, DeleteMessageAction, DeleteMessagesAction } from './alerts.types'

export const createSuccessAction = (message: string): CreateMessageAction => ({
  type: Action.CREATE_MESSAGE,
  data: {
    id: createUuid(),
    type: 'success',
    content: message,
    createdAt: new Date(),
  },
})

export const createInfoAction = (message: string): CreateMessageAction => ({
  type: Action.CREATE_MESSAGE,
  data: {
    id: createUuid(),
    type: 'info',
    content: message,
    createdAt: new Date(),
  },
})

export const createWarningAction = (message: string): CreateMessageAction => ({
  type: Action.CREATE_MESSAGE,
  data: {
    id: createUuid(),
    type: 'warning',
    content: message,
    createdAt: new Date(),
  },
})

export const createErrorAction = (message: string): CreateMessageAction => ({
  type: Action.CREATE_MESSAGE,
  data: {
    id: createUuid(),
    type: 'error',
    content: message,
    createdAt: new Date(),
  },
})

export const deleteMessageAction = (id: string): DeleteMessageAction => ({
  type: Action.DELETE_MESSAGE,
  data: {
    id,
  },
})

export const deleteMessagesAction = (): DeleteMessagesAction => ({
  type: Action.DELETE_MESSAGES,
  data: undefined,
})
