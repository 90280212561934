import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './ProgressButton.module.scss'

type Classes = {
  root?: string
  progress?: string
}

const defaultProps = {
  loading: false as boolean,
  classes: {} as Classes,
  progressSize: 24 as number,
}

type Props = ButtonProps & typeof defaultProps

/// todo: Refaktor
const ProgressButton = (props: Props) => {
  const { classes, loading, progressSize, ...buttonProps } = props
  const buttonClasses = classNames(props.className, classes.root)
  const progressClasses = classNames(classes.progress, styles['progress'])

  return (
    <Button {...buttonProps} className={buttonClasses} disabled={props.disabled || loading}>
      {props.children}
      {loading && <CircularProgress size={progressSize} className={progressClasses} />}
    </Button>
  )
}

ProgressButton.defaultProps = defaultProps

export default ProgressButton
