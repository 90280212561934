import _ from 'lodash'
import * as yup from 'yup'
import createError from '../helper/createError'
import { EQUAL_TO_ERROR } from '../messages'

const validator = (field2: string, value2: string, message?: string): yup.StringSchema => {
  const errorMessage: string = message || _.replace(EQUAL_TO_ERROR, '{field}', field2)
  return yup.mixed().test('equalTo', errorMessage, function (this: yup.TestContext, value: any) {
    return value === value2 || createError(errorMessage, this)
  }) as yup.StringSchema
}

export default validator
