import _ from 'lodash'
import adminRoutes, { links as adminLinks } from './admin'
import authRoutes, { links as authLinks } from './auth'
import errorRoutes, { links as errorLinks } from './error'
import userRoutes, { links as userLinks } from './user'
import { Route } from '../../types/Routes'
import * as UserRole from '../../types/UserRole'

export const links = Object.freeze({
  ...authLinks,
  ...userLinks,
  ...adminLinks,
  ...errorLinks,
})

const routes = [...authRoutes, ...userRoutes, ...adminRoutes, ...errorRoutes].map(route => {
  if (route.roles.includes(UserRole.IS_AUTHENTICATED_FULLY)) {
    route.roles.push(UserRole.ROLE_USER)
  } else if (route.roles.includes(UserRole.ROLE_USER)) {
    route.roles.push(UserRole.IS_AUTHENTICATED_FULLY)
  }

  if (route.roles.includes(UserRole.ROLE_USER)) {
    route.roles.push(UserRole.ROLE_ADMIN)
  }

  if (route.roles.includes(UserRole.ROLE_ADMIN)) {
    route.roles.push(UserRole.ROLE_SUPER_ADMIN)
  }

  return {
    ...route,
    roles: _.uniqBy(route.roles, role => role),
  }
})

export const routesByPath = routes.reduce((result: Record<string, Route>, curr: Route) => {
  if (curr.path) {
    if (_.isArray(curr.path)) {
      for (const p of curr.path) {
        result[p] = curr
      }
    } else {
      result[curr.path] = curr
    }
  }

  return result
}, {})

export default routes
