import Poliglotos from '../backgrounds/poliglotos_auth-background.jpg'
// import Speakking from '../backgrounds/speakking_auth-background.jpg'

const BrandBackground: { [key: string]: any } = {
  poliglotos: Poliglotos,
  // speakking: Speakking,
  speakking: '#F2F2F2 0 0 no-repeat padding-box',
}

export { BrandBackground }
