import Box from '@material-ui/core/Box'
import classNames from 'classnames'
import React from 'react'
import { renderRoutes } from 'react-router-config'
import styles from './ContentView.module.scss'
import TransitionWrapper from './TransitionWrapper'
import AppErrorBoundary from '../../../../../_crema/@crema/core/AppErrorBoundary'
import AppSuspense from '../../../../../_crema/@crema/core/AppSuspense'
import Scrollbar from '../../../../../_crema/@crema/core/Scrollbar'
import AppContext, { Type as AppContextType } from '../../../../../contexts/AppContext'

const ContentView = () => {
  const { routes } = React.useContext<AppContextType>(AppContext)

  return (
    <Scrollbar>
      <Box className={classNames('main-content-view', styles['container'])}>
        <AppSuspense>
          <AppErrorBoundary>
            <TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
    </Scrollbar>
  )
}

export default ContentView
