import { endpoints } from '../../config/api'
import User from '../../types/User'
import axios, { AxiosResponse } from '../../utils/axios'
import {
  SignInPayload,
  SignInResult,
  SignOutPayload,
  SignOutResult,
  SignUpPayload,
  SignUpResult,
  RefreshTokenResult,
  RefreshTokenPayload,
  ChangePasswordPayload,
  ChangePasswordResult,
  ResetPasswordPayload,
  ResetPasswordResult,
  SignUpGuestPayload,
  SignUpGuestResult,
  SignUpActivationPayload,
  SignUpActivationResult,
} from './auth.types'

const buildTokenFromResponse = (response: AxiosResponse): string => response.data.token || ''

const buildUserFromResponse = (response: AxiosResponse): User => ({
  id: response.data.user.id,
  name: response.data.user.name,
  displayName: response.data.user.name,
  photoUrl: response.data.user.avatar || null,
  packagesForActivation: response.data.user.packagesForActivation,
  email: response.data.user.email,
  phone: response.data.user.phone,
  roles: response.data.user.roles,
  createdAt: new Date(response.data.user.createdAt),
  lastLoggedIn: response.data.user.lastLoggedIn ? new Date(response.data.user.lastLoggedIn) : null,
  active: response.data.user.active,
  certificates: response.data.user.certificates,
  prefix: response.data.user.prefix || null,
})

export const signUp = async (payload: SignUpPayload): Promise<SignUpResult> =>
  axios.post<SignUpPayload, AxiosResponse>(endpoints.authSignUp.path, payload).then(response => ({
    token: buildTokenFromResponse(response),
    user: buildUserFromResponse(response),
  }))

export const signUpGuest = async (payload: SignUpGuestPayload): Promise<SignUpGuestResult> =>
  axios.post<SignUpGuestPayload, AxiosResponse>(endpoints.authSignUpGuest.path, {
    phone: payload.phone,
    name: payload.firstName,
    agreement_1: payload.agreement1,
    agreement_2: payload.agreement2,
    agreement_3: payload.agreement3,
  })

export const activate = async (payload: Omit<SignUpActivationPayload, 'agreement'>): Promise<SignUpActivationResult> =>
  axios.post<Omit<SignUpActivationPayload, 'agreement'>, AxiosResponse>(endpoints.authSignUpActivate.path, payload).then(response => ({
    token: buildTokenFromResponse(response),
    user: buildUserFromResponse(response),
    smsCode: response.data.smsCode || '',
  }))

export const signIn = async (payload: Omit<SignInPayload, 'country'>): Promise<SignInResult> =>
  axios.post<SignInPayload, AxiosResponse>(endpoints.authSignIn.path, payload).then(response => ({
    token: buildTokenFromResponse(response),
    user: buildUserFromResponse(response),
  }))

export const signOut = async (): Promise<SignOutResult> =>
  axios.get<SignOutPayload, AxiosResponse>(endpoints.authSignOut.path).then(() => undefined)

export const refreshToken = async (): Promise<RefreshTokenResult> =>
  axios.get<RefreshTokenPayload, AxiosResponse>(endpoints.authRefreshToken.path).then(response => ({
    token: buildTokenFromResponse(response),
    user: buildUserFromResponse(response),
  }))

export const resetPassord = async (payload: ResetPasswordPayload): Promise<ResetPasswordResult> =>
  axios.post<ResetPasswordPayload, AxiosResponse>(endpoints.authResetPassword.path, payload).then(() => undefined)

export const changePassword = async (payload: ChangePasswordPayload): Promise<ChangePasswordResult> =>
  axios
    .post<ChangePasswordPayload, AxiosResponse>(endpoints.authChangePassword.path, {
      code: payload.code,
      password: payload.newPassword,
    })
    .then(response => ({
      token: buildTokenFromResponse(response),
      user: buildUserFromResponse(response),
    }))
