import MockAdapter from 'axios-mock-adapter'
import axios from './axios'

export const url = (path: string | null | string): RegExp | string => {
  return typeof path === 'string' ? new RegExp(path.replace(/:\w+/g, '[^/]+')) : ''
}

const mock = new MockAdapter(axios, {
  delayResponse: 1000,
  onNoMatch: 'passthrough',
})

export default mock
