import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import React from 'react'
import styles from './PackageActivationModal.module.scss'
import ActivationForm from '../../ActivationForm'

type Props = {
  open: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown', updated: boolean) => void
}

const PackageActivationModal = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles['modal']}
      onClose={(event: {}, reason: 'backdropClick' | 'escapeKeyDown') => props.onClose(event, reason, false)}
      open={props.open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={styles['modal__container']}>
          <ActivationForm classes={{ card: styles['modal__content'] }} onClose={props.onClose} />
        </div>
      </Fade>
    </Modal>
  )
}

export default PackageActivationModal
