import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import defaultConfig from '../../../_crema/@crema/utility/ContextProvider/defaultConfig'
import { CremaTheme } from '../../../_crema/types/AppContextPropsType'
import {
  FontFamily,
  ThemeMode,
  RouteTransition,
  ThemeStyle,
  NavStyle,
  LayoutType,
  HeaderType,
  FooterType,
  ThemeStyleRadius,
} from '../../../_crema/shared/constants/AppEnums'

export type Type = CremaTheme

const breakpoints = createBreakpoints({})
const theme: Type = {
  ...defaultConfig.theme,
  themeStyle: ThemeStyle.MODERN,
  themeMode: ThemeMode.JEZYKOWY_GURU,
  navStyle: NavStyle.DEFAULT,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.DARK,
  rtAnim: RouteTransition.NONE,
  spacing: 4,
  palette: {
    type: ThemeMode.LIGHT,
    background: {
      paper: '#FFFFFF',
      default: '#F4F7FE',
    },
    primary: {
      main: '#003f88',
      light: '#282828',
      dark: '#003f88',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F04F47',
      light: 'rgba(0, 0, 0, 0.0)',
    },
    sidebar: {
      bgColor: 'rgba(0, 0, 0, 0.0)',
      textColor: '#282828',
    },
    common: {
      white: '#fff',
      black: '#282828',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#282828',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  status: {
    danger: 'orange',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  typography: {
    fontFamily: [FontFamily.REGULAR, 'sans-serif'].join(','),
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 36,
      },
      h2: {
        fontSize: 30,
      },
      h3: {
        fontSize: 24,
      },
      h4: {
        fontSize: 22,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 16,
      },
      subtitle1: {
        fontSize: 18,
      },
      subtitle2: {
        fontSize: 20,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: ThemeStyleRadius.STANDARD,
      },
    },
    MuiCardLg: {
      root: {
        borderRadius: ThemeStyleRadius.STANDARD,
      },
    },
    MuiCard: {
      root: {
        borderRadius: ThemeStyleRadius.STANDARD,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#D1E6FF',
          color: '#282828',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#003f88',
      },
    },
    MuiButton: {
      root: {
        borderRadius: ThemeStyleRadius.MODERN,
        [breakpoints.down('md')]: {
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        },
      },
      contained: {
        color: '#fff',
        backgroundColor: '#fd5601',
        '&:hover': {
          backgroundColor: '#ff7129',
        },
        primary: {
          backgroundColor: '#fd5601',
        }
      },
    },
  },
}

export default theme
