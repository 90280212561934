import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import classNames from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styles from './ActivationForm.module.scss'
import validationSchema from './ActivationForm.schema'
import { FontFamily } from '../../_crema/shared/constants/AppEnums'
import useAuth from '../../hooks/useAuth'
import { createErrorAction } from '../../services/alerts/alerts.actions'
import { ActivateCodePayload } from '../../services/courses/courses.types'
import submitForm from '../../utils/submitForm'
import ProgressButton from '../UI/ProgressButton/ProgressButton'
import TextField from '../UI/TextField/TextField'

type Props = {
  classes?: {
    card?: string
  }
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown', updated: boolean) => void
}

const ActivationForm = (props: Props) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { user, activate } = useAuth()

  const handleSubmit = async (values: ActivateCodePayload, formikBag: FormikHelpers<ActivateCodePayload>) => {
    await submitForm(
      formikBag,
      async () => {
        await activate(values)

        if (props.onClose) {
          props.onClose({}, 'backdropClick', true)
        }
      },
      async error => {
        dispatch(createErrorAction(error.response.data.message))
      },
    )
  }

  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Card className={classNames(styles['card'], props.classes?.card)}>
          <Box px={{ xs: 6, sm: 10, xl: 15 }}>
            <Box component="h3" mb={{ xs: 4, xl: 10 }} fontSize={{ xs: 24, md: 28 }} fontFamily={FontFamily.LIGHT}>
              Aktywuj pakiet
            </Box>
          </Box>
          <Box flex={1} display="flex" flexDirection="column">
            <Box px={{ xs: 6, sm: 10, xl: 15 }} pt={{ xs: 8, xl: 12 }} flex={1} display="flex" flexDirection="column">
              <Formik
                initialValues={
                  {
                    phone: user?.phone || '',
                    code: '',
                  } as ActivateCodePayload
                }
                validateOnChange={true}
                validationSchema={validationSchema}
                onSubmit={async (values, formikBag) => await handleSubmit(values, formikBag)}
              >
                {({ isSubmitting, values }) => (
                  <Form className={styles['form']} noValidate autoComplete="off">
                    <input type="hidden" name="phone" value={values.phone} />
                    <Box mb={{ xs: 5, lg: 6 }}>
                      <TextField
                        placeholder={formatMessage({ id: 'common.inputCode', defaultMessage: 'Kod aktywacyjny' })}
                        name="code"
                        label={<FormattedMessage id="common.inputCode" defaultMessage="Kod aktywacyjny" />}
                        className={styles['text-field']}
                        variant="outlined"
                        classes={{ root: styles['text-field'] }}
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                      />
                    </Box>
                    <Box mt={6} mb={3} display="flex" alignItems="center" justifyContent="center">
                      <ProgressButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        className={styles['button']}
                        loading={isSubmitting}
                      >
                        <FormattedMessage id="common.activateCode" defaultMessage="Aktywuj" />
                      </ProgressButton>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default ActivationForm
