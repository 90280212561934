import * as yup from 'yup'
import createError from '../helper/createError'
import { UUID_ERROR } from '../messages'

const v1Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i
const v2Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[2][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i
const v3Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[3][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i
const v4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i
const v5Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i
const nilRegex = /^[0]{8}-[0]{4}-[0]{4}-[0]{4}-[0]{12}$/i
const anyRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const v1 = (str?: string): boolean => {
  const uuid = str || ''
  return v1Regex.test(uuid)
}

export const v2 = (str?: string): boolean => {
  const uuid = str || ''
  return v2Regex.test(uuid)
}

export const v3 = (str?: string): boolean => {
  const uuid = str || ''
  return v3Regex.test(uuid)
}

export const v4 = (str?: string): boolean => {
  const uuid = str || ''
  return v4Regex.test(uuid)
}

export const v5 = (str?: string): boolean => {
  const uuid = str || ''
  return v5Regex.test(uuid)
}

export const nil = (str?: string): boolean => {
  const uuid = str || ''
  return nilRegex.test(uuid)
}

export const anyNonNil = (str?: string): boolean => {
  const uuid = str || ''
  return anyRegex.test(uuid)
}

const validator = (message?: string): yup.StringSchema => {
  const errorMessage: string = message || UUID_ERROR
  return yup.mixed().test('uuid', errorMessage, function (this: yup.TestContext, value: any) {
    return anyNonNil(`${value}`) || createError(errorMessage, this)
  }) as yup.StringSchema
}

export default validator
