import _ from 'lodash'
import { Action, ActionTypes, State } from './alerts.types'

const initialState: Readonly<State> = {
  messages: [],
}

const alerts = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Action.CREATE_MESSAGE: {
      const newState = _.cloneDeep(state)
      newState.messages.push({ ...action.data })
      return newState
    }

    case Action.DELETE_MESSAGE: {
      return _.cloneDeep({
        ...state,
        messages: state.messages.filter(message => message.id !== action.data.id),
      })
    }

    case Action.DELETE_MESSAGES:
      return _.cloneDeep(initialState)

    default:
      return state
  }
}

export default alerts
