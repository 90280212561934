import React from 'react'
import { renderRoutes } from 'react-router-config'
import AppSuspense from '../../../../_crema/@crema/core/AppSuspense'
import AppContext, { Type as AppContextType } from '../../../../contexts/AppContext'

const NullLayout = () => {
  const { routes } = React.useContext<AppContextType>(AppContext)

  return <AppSuspense>{renderRoutes(routes)}</AppSuspense>
}

export default NullLayout
