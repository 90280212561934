const brandConfig: any = {
  phones: {
    speakking: {
      href: 'tel:222437466',
      formatted: '22 243 74 66',
    },
    poliglotos: {
      href: 'tel:225987886',
      formatted: '22 598 78 86',
    },
    jezykowyguru: {
      href: 'https://jezykowyguru.pl',
      formatted: 'jezykowyguru.pl',
    },
  },
  reactivation: {
    speakking: {
      href: 'tel:222436693',
      formatted: '22 243 66 93',
    },
    poliglotos: {
      href: 'tel:717026306',
      formatted: '71 702 63 06',
    },
    jezykowyguru: {
      href: 'https://jezykowyguru.pl',
      formatted: 'jezykowyguru.pl',
    },
  },
}

export default brandConfig
