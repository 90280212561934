import _ from 'lodash'
import yup from './helper/yup'
import course from './schema/course'
import user from './schema/user'

export const EMPTY_ERROR = 'Pole nie może być puste'
export const EMPTY_PHONE_ERROR = 'Wpisz numer telefonu z którego składałeś zamówienie - będzie to Twój login do aplikacji'
export const EMPTY_CODE = 'Przepisz dokładnie swój kod aktywacyjny'
export const EMPTY_CODE_PASSWORD = 'Wpisz kod autoryzacyjny z wiadomości SMS - jest on aktywny przez 5 minut'
export const REPEATED_PASSWORD_ERROR = 'Wpisz takie samo hasło jak w polu powyżej'
export const EMPTY_PASSWORD = 'Wpisz hasło'
export const EMPTY_SURNAME_ERROR = 'Wpisz swoje nazwisko'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...yup,
  user: () => _.cloneDeep(user),
  course: () => _.cloneDeep(course),
}
