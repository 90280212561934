import React, { useReducer } from 'react'
import { contextReducer } from '../../../_crema/@crema/utility/ContextProvider/ContextReducer'
import defaultConfig from '../../../config/app'
import routes from '../../../config/routes'
import AppContext from '../../../contexts/AppContext'
import Children from '../../../types/Children'

export const ContextState = {
  theme: defaultConfig.theme,
  footer: defaultConfig.footer,
  footerType: defaultConfig.footerType,
  themeMode: defaultConfig.themeMode,
  headerMode: defaultConfig.headerMode,
  themeStyle: defaultConfig.themeStyle,
  layoutType: defaultConfig.layoutType,
  isRTL: defaultConfig.theme.direction === 'rtl',
  locale: defaultConfig.locale,
  navStyle: defaultConfig.navStyle,
  rtAnim: defaultConfig.rtAnim,
  primary: defaultConfig.theme.palette.primary.main,
  sidebarColor: defaultConfig.theme.palette.sidebar.bgColor,
  secondary: defaultConfig.theme.palette.secondary.main,
}

type Props = {
  children: Children
}

const AppProvider = (props: Props) => {
  const [state] = useReducer(contextReducer, ContextState, () => ContextState)

  return (
    <AppContext.Provider
      value={{
        ...state,
        initialPath: defaultConfig.initialPath,
        routes,
        rtlLocale: defaultConfig.rtlLocale,
        brand: defaultConfig.brand,
        phones: defaultConfig.phones,
        reactivation: defaultConfig.reactivation,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
