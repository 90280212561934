import BaseTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { FieldHookConfig, useField } from 'formik'
import React from 'react'

type Props = TextFieldProps & FieldHookConfig<any>

/// todo: Refaktor
const TextField = (props: Props) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return <BaseTextField {...props} {...field} helperText={errorText} error={!!errorText} />
}

export default TextField
