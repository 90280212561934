import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import NoSsr from '@material-ui/core/NoSsr'
import React from 'react'

interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => {
  return (
    <NoSsr>
      <Box
        height="100%"
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <CircularProgress color="secondary" />
      </Box>
    </NoSsr>
  )
}

export default Loader
