import { Icon, ListItem, ListItemText } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import React, { useContext, useMemo } from 'react'
import useStyles from './VerticalItem.style'
import NavLink from '../../../../../../_crema/@crema/core/AppNavLink'
import Badge from '../../../../../../_crema/@crema/core/Badge'
import IntlMessages from '../../../../../../_crema/@crema/utility/IntlMessages'
import { NavItemProps } from '../../../../../../_crema/modules/routesConfig'
import AppContext from '../../../../../../contexts/AppContext'
import useAuth from '../../../../../../hooks/useAuth'
import { hasAccess } from '../../../../../../types/User'

interface VerticalItemProps {
  item: NavItemProps
  level: number
}

const VerticalItem: React.FC<VerticalItemProps> = ({ item, level }) => {
  const { themeMode } = useContext(AppContext)
  const classes = useStyles({ level, themeMode })
  const { user } = useAuth()
  const hasPermission = useMemo(() => hasAccess(user, item.auth), [item.auth, user])

  if (!hasPermission) {
    return null
  }

  return (
    <ListItem
      button
      component={NavLink}
      to={item.url}
      activeClassName="active"
      className={clsx(classes.navItem, 'nav-item')}
      exact={item.exact}
      target={item.target || '_self'}
    >
      {item.icon && (
        <Box component="span" mr={6}>
          <Icon className={clsx(classes.listIcon, 'nav-item-icon')} color="action">
            {item.icon}
          </Icon>
        </Box>
      )}
      <ListItemText primary={<IntlMessages id={item.messageId} />} classes={{ primary: 'nav-item-text' }} />
      {item.count && (
        <Box mr={1} clone>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}
    </ListItem>
  )
}

export default React.memo(VerticalItem)
