import {
  Action,
  SignInResult,
  SignOutResult,
  SignUpResult,
  SignUpActivationResult,
  SignUpAction,
  SignUpActivationAction,
  SignInAction,
  SignOutAction,
  RefreshTokenAction,
  RefreshTokenResult,
  ForgotPasswordAction,
  ResetPasswordResult,
  ChangePasswordAction,
  ChangePasswordResult,
} from './auth.types'

export const signUpAction = (result: SignUpResult): SignUpAction => ({
  type: Action.SIGN_UP,
  data: result,
})

export const signUpActivationAction = (result: SignUpActivationResult): SignUpActivationAction => ({
  type: Action.SIGN_UP_ACTIVATION,
  data: result,
})

export const signInAction = (result: SignInResult): SignInAction => ({
  type: Action.SIGN_IN,
  data: result,
})

export const signOutAction = (result: SignOutResult): SignOutAction => ({
  type: Action.SIGN_OUT,
  data: result,
})

export const refreshTokenAction = (result: RefreshTokenResult): RefreshTokenAction => ({
  type: Action.UPDATE_TOKEN,
  data: result,
})

export const forgotPasswordAction = (result: ResetPasswordResult): ForgotPasswordAction => ({
  type: Action.FORGOT_PASSWORD,
  data: result,
})

export const changePasswordAction = (result: ChangePasswordResult): ChangePasswordAction => ({
  type: Action.CHANGE_PASSWORD,
  data: result,
})
