import _ from 'lodash'
import { Action, ActionTypes, State } from './settings.types'
import appConfig from '../../config/app'
import locales, { DEFAULT_FULL_ISO_CODE, FullIsoCode } from '../../config/locales'

const fullIsoCode: FullIsoCode =
  (Object.keys(locales) as FullIsoCode[]).map(fullIsoCode => locales[fullIsoCode]).find(item => item.isoCode === appConfig.defaultLocale)
    ?.fullIsoCode || DEFAULT_FULL_ISO_CODE

const initialState: Readonly<State> = {
  initialPath: appConfig.initialPath,
  defaultLocale: locales[fullIsoCode].isoCode,
  language: {
    isoCode: locales[fullIsoCode].isoCode,
    fullIsoCode: locales[fullIsoCode].fullIsoCode,
    name: locales[fullIsoCode].name,
    flag: locales[fullIsoCode].flag,
  },
  navCollapsed: false,
  layout: appConfig.navStyle,
}

const settings = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Action.ROUTE_CHANGE:
      return {
        ..._.cloneDeep(state),
        navCollapsed: false,
      }

    case Action.TOGGLE_NAV_COLLAPSED:
      return {
        ..._.cloneDeep(state),
        navCollapsed: !state.navCollapsed,
      }

    case Action.SET_INITIAL_PATH:
      return {
        ..._.cloneDeep(state),
        initialPath: action.data.initialPath,
      }

    case Action.CHANGE_LANGUAGE:
      return {
        ..._.cloneDeep(state),
        language: _.cloneDeep(action.data),
      }

    default:
      return state
  }
}

export default settings
