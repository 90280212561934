const authApi = Object.freeze({
  authSignIn: {
    method: 'POST',
    path: '/login',
  },
  authSignUp: {
    method: 'POST',
    path: '/auth/sign-up',
  },
  authSignUpGuest: {
    method: 'POST',
    path: '/lead/new',
  },
  authSignUpActivate: {
    method: 'POST',
    path: '/code/activate',
  },
  authSignOut: {
    method: 'GET',
    path: '/auth/logout',
  },
  authRefreshToken: {
    method: 'GET',
    path: '/token/refresh',
  },
  authResetPassword: {
    method: 'POST',
    path: '/sms/send',
  },
  authChangePassword: {
    method: 'POST',
    path: '/password/reset',
  },
})

const newsletterApi = Object.freeze({
  newsletterSubscribe: {
    method: 'POST',
    path: '/newsletter',
  },
})

const courseApi = Object.freeze({
  courseGetLanguages: {
    method: 'GET',
    path: '/language',
  },
  courseGetPackages: {
    method: 'GET',
    path: '/language/:languageId/package',
  },
  courseGetLessons: {
    method: 'GET',
    path: '/package/:packageId/lesson',
  },
  courseGetLesson: {
    method: 'GET',
    path: '/package/:packageId/lesson/:lessonId/word',
  },
  courseGetChapters: {
    method: 'GET',
    path: '/package/:packageId/chapter',
  },
  courseGetChapter: {
    method: 'GET',
    path: '/package/:packageId/chapter/:chapterId/recording',
  },
  coursePostChapterProgress: {
    method: 'POST',
    path: '/recording-progress'
  },
  courseGetLessonSummary: {
    method: 'GET',
    path: '/package/:packageId/lesson/:lessonId/summary',
  },
  coursePostLessonProgress: {
    method: 'POST',
    path: '/progress',
  },
  courseResetLessonProgress: {
    method: 'POST',
    path: '/package/:packageId/lesson/:lessonId/reset',
  },
  courseGetCertificates: {
    method: 'GET',
    path: '/certificates',
  },
  courseGenerateCertificate: {
    method: 'POST',
    path: '/package/:packageId/certificate',
  },
  courseDownloadCertificate: {
    method: 'GET',
    path: '/certificate/:certificateId/download',
  },
  coursePostCertificateLead: {
    method: 'POST',
    path: '/lead/new',
  },
})

const ebookApi = Object.freeze({
  courseGetEbooks: {
    method: 'GET',
    path: '/ebooks',
  },
})

const userApi = Object.freeze({
  userChangePassword: {
    method: 'POST',
    path: '/password/new',
  },
  userStats: {
    method: 'GET',
    path: '/statistics',
  },
  userUploadAvatar: {
    method: 'POST',
    path: '/user/avatar/new',
  },
  userRemoveAvatar: {
    method: 'POST',
    path: '/user/avatar/remove',
  },
})

export const endpoints = Object.freeze({
  ...authApi,
  ...newsletterApi,
  ...courseApi,
  ...ebookApi,
  ...userApi,
})
