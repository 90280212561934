import { NavStyle } from '../../_crema/shared/constants/AppEnums'
import { Setting } from '../../_crema/types/models/Setting'
import { FullIsoCode, IsoCode2, Type as LanguageType } from '../../config/locales'
import ReduxAction from '../../types/ReduxAction'

export enum Action {
  TOGGLE_NAV_COLLAPSED = 'TOGGLE_NAV_COLLAPSED',
  SET_INITIAL_PATH = 'SET_INITIAL_PATH',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  ROUTE_CHANGE = '@@router/LOCATION_CHANGE',
}

export type State = Setting & {
  defaultLocale: IsoCode2
  language: Omit<LanguageType, 'messages' | 'muiLocale'>
  layout: NavStyle
}

export type ToggleNavCollapsed = ReduxAction<typeof Action.TOGGLE_NAV_COLLAPSED, ToggleNavCollapsedResult>
export type SetInitialPathAction = ReduxAction<typeof Action.SET_INITIAL_PATH, RouteChangeResult>
export type ChangeLanguageAction = ReduxAction<typeof Action.CHANGE_LANGUAGE, ChangeLanguageResult>
export type RouteChangeAction = ReduxAction<typeof Action.ROUTE_CHANGE, RouteChangeResult>
export type ActionTypes = ToggleNavCollapsed | SetInitialPathAction | ChangeLanguageAction | RouteChangeAction

export type ToggleNavCollapsedResult = undefined

export type ChangeLanguageResult = {
  isoCode: IsoCode2
  fullIsoCode: FullIsoCode
  name: string
  flag: string
}

export type RouteChangeResult = {
  initialPath: string | undefined
}
