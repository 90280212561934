import React from 'react'
import Routes from '../../types/Routes'
import * as UserRole from '../../types/UserRole'

export const links = Object.freeze({
  error401: {
    name: 'Error 401',
    path: '/error/401',
    component: React.lazy(() => import('../../pages/Error/Error401Page')),
  },
  error403: {
    name: 'Error 403',
    path: '/error/403',
    component: React.lazy(() => import('../../pages/Error/Error403Page')),
  },
  error404: {
    name: 'Error 404',
    path: '/error/404',
    component: React.lazy(() => import('../../pages/Error/Error404Page')),
  },
  error500: {
    name: 'Error 500',
    path: '/error/500',
    component: React.lazy(() => import('../../pages/Error/Error500Page')),
  },
  commingSoon: {
    name: 'Comming Soon',
    path: '/error/coming-soon',
    component: React.lazy(() => import('../../pages/Error/CommingSoonPage')),
  },
  maintenance: {
    name: 'Maintenance',
    path: '/error/maintenance',
    component: React.lazy(() => import('../../pages/Error/MaintenancePage')),
  },
})

const routes: Routes = Object.keys(links)
  .map(key => links[key as keyof typeof links])
  .map(route => ({
    ...route,
    roles: [UserRole.IS_AUTHENTICATED_ANONYMOUSLY, UserRole.IS_AUTHENTICATED_FULLY],
    onlyAuth: false,
    onlyNotAuth: false,
  }))

export default routes
