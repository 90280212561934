import React from 'react'
import Routes from '../../types/Routes'
import * as UserRole from '../../types/UserRole'

export const links = Object.freeze({
  userWelcomePage: {
    name: 'Welcome',
    path: '/welcome',
    exact: true,
    component: React.lazy(() => import('../../pages/User/WelcomePage')),
  },
  userLanguages: {
    name: 'User Languages',
    path: '/',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserLanguagesPage')),
  },
  userPackages: {
    name: 'User Packages',
    path: '/:languageId/package',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserPackagesPage')),
  },
  userLessons: {
    name: 'User Lessons',
    path: '/package/:packageId/lesson',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserLessonsPage')),
  },
  userLessonSummary: {
    name: 'User Lesson',
    path: '/package/:packageId/lesson/:lessonId/summary',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserLessonSummaryPage')),
  },
  userLesson: {
    name: 'User Lesson',
    path: '/package/:packageId/lesson/:lessonId',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserLessonPage')),
  },
  userChapters: {
    name: 'User Chapters',
    path: '/package/:packageId/chapter',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserChaptersPage')),
  },
  userChapter: {
    name: 'User Chapter',
    path: '/package/:packageId/chapter/:chapterId',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserChapterPage')),
  },
  userStats: {
    name: 'User Stats',
    path: '/stats',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserStats')),
  },
  userAccount: {
    name: 'User Account',
    path: '/account',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserAccount')),
  },
  userCertificates: {
    name: 'User Certificates',
    path: '/certificates',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserCertificates')),
  },
  userEbooks: {
    name: 'User E-books',
    path: '/ebooks',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserEbooksPage')),
  },
  userEbook: {
    name: 'User E-books',
    path: '/ebook/:ebookId',
    exact: true,
    component: React.lazy(() => import('../../pages/User/UserEbookPage')),
  },
})

const routes: Routes = Object.keys(links)
  .map(key => links[key as keyof typeof links])
  .map(route => ({
    ...route,
    roles: [UserRole.ROLE_USER, route.path === '/welcome' ? UserRole.IS_AUTHENTICATED_ANONYMOUSLY : UserRole.ROLE_USER].filter(Boolean),
    onlyAuth: true,
    onlyNotAuth: false,
  }))

export default routes
