import axiosLib, { AxiosError as _AxiosError, AxiosResponse as _AxiosResponse, AxiosTransformer } from 'axios'
import _ from 'lodash'
import store from '../../services/store'
import Errors from '../../types/Errors'
import { createErrors } from '../../types/Errors'
import defaultConfig from '../../config/app'
import BrandApi from "../BrandApi";

export type AxiosResponse<T = any> = _AxiosResponse<T>
export type AxiosError = _AxiosError<Errors> & {
  response: {
    data: Errors
  }
}

type Data = any
type Headers = Record<string, string>

const TIMEOUT = 30000

const requestTransformers: AxiosTransformer[] = axiosLib.defaults.transformRequest
  ? _.isArray(axiosLib.defaults.transformRequest)
    ? axiosLib.defaults.transformRequest
    : [axiosLib.defaults.transformRequest]
  : []

const responseTransformers: AxiosTransformer[] = axiosLib.defaults.transformResponse
  ? _.isArray(axiosLib.defaults.transformResponse)
    ? axiosLib.defaults.transformResponse
    : [axiosLib.defaults.transformResponse]
  : []

const transformRequest: AxiosTransformer = (data: Data, headers: Headers) => {
  const authorization = store.getState().auth.token

  if (authorization) {
    headers['x-auth-token'] = authorization
  }

  return data
}

const transformResponse: AxiosTransformer = (data: Data, headers: Headers) => {
  return data
}

const axios = axiosLib.create({
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [...requestTransformers, transformRequest],
  transformResponse: [...responseTransformers, transformResponse],
})

axios.interceptors.request.use(config => ({
  ...config,
  baseURL: BrandApi[defaultConfig.brand] as string,
}))

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: _AxiosError<any>): Promise<AxiosError> => {
    const errors = createErrors(
      error.response?.data?.error || error.response?.data?.message,
      error.response?.status,
      error.response?.data?.errors,
    )
    const result: AxiosError = {
      ...error,
      response: {
        data: errors,
        status: errors.statusCode,
        statusText: error.response?.statusText || errors.message,
        headers: error.response || {},
        config: error.response?.config || {},
      },
    }

    return Promise.reject(result)
  },
)

export default axios
