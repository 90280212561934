import { ActionTypes, State } from './newsletter.types'

const initialState: Readonly<State> = {}

const auth = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    default:
      return state
  }
}

export default auth
