import React from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import { links } from '../../../config/routes'
import AppContext from '../../../contexts/AppContext'
import useAuth from '../../../hooks/useAuth'
import AuthLayout from '../AppLayout/AuthLayout'
// import Layouts from '../AppLayout/Layouts'
import NullLayout from '../AppLayout/NullLayout'
import DefaultMiniLayout from '../AppLayout/DefaultMiniLayout'

const LayoutProvider = () => {
  const { pathname } = useLocation()
  const { brand } = React.useContext(AppContext)
  const { user, isSignedIn, refreshToken } = useAuth()

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (isSignedIn && !matchPath(pathname, {path: links.userChapter.path})) {
      intervalId = setInterval(async () => {
        await refreshToken()
      }, 60000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [refreshToken, isSignedIn, pathname])

  // const AppLayout = Layouts[navStyle]

  return !user || !user.active || pathname === links.userWelcomePage.path || pathname === links.privacyPolicy.path ? (
    pathname === links.privacyPolicy.path ? (
      <NullLayout />
    ) : (
      <AuthLayout branding={brand} />
    )
  ) : (
    <DefaultMiniLayout />
  )
}

export default React.memo(LayoutProvider)
