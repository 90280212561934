import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import clsx from 'clsx'
import React, { useContext } from 'react'
import AppFixedFooter from './AppFixedFooter'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'
import useStyles from './index.style'
import { LayoutType } from '../../../../_crema/shared/constants/AppEnums'
import AppContext, { Type as AppContextType } from '../../../../contexts/AppContext'
import { BrandBackground } from '../../../../assets/images/backgrounds'
import ContentView from '../shared/ContentView'

interface DefaultLayoutProps {}

const DefaultLayout = (props: DefaultLayoutProps) => {
  const { themeStyle, footer, layoutType, footerType, brand } = useContext<AppContextType>(AppContext)
  const classes = useStyles({ themeStyle, footer })

  return (
    <Box className={clsx(classes.appContainer)} style={{ background: `url('${BrandBackground[brand]}')` }}>
      <AppHeader />
      <Box
        className={clsx(classes.appMain, layoutType === LayoutType.BOXED ? classes.boxedLayout : '', {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: false,
        })}
      >
        <Box className={classes.mainContent}>
          <AppSidebar />

          <Hidden mdDown>
            <Box className={classes.mainContainer}>
              <ContentView />
            </Box>
          </Hidden>

          <Hidden lgUp>
            <Box className={classes.mainContainerFull}>
              <ContentView />
            </Box>
          </Hidden>
        </Box>
        <AppFixedFooter />
      </Box>
    </Box>
  )
}

export default DefaultLayout
