import Poliglotos from '../poliglotos/logo-with-name.png'
import PoliglotosWhite from '../poliglotos/logo-white-with-name.png'
import Speakking from '../speakking/logo-with-name.png'
import SpeakkingWhite from '../speakking/logo-white-with-name.png'
import Jezykowyguru from '../jezykowyguru/logo-with-name.png'
import JezykowyguruWhite from '../jezykowyguru/logo-white-with-name.png'

const BrandLogo: { [key: string]: any } = {
  poliglotos: {
    normal: Poliglotos,
    white: PoliglotosWhite,
  },
  speakking: {
    normal: Speakking,
    white: SpeakkingWhite,
  },
  jezykowyguru: {
    normal: Jezykowyguru,
    white: JezykowyguruWhite,
  },
}

export { BrandLogo }
