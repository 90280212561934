import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import alerts from './alerts/alerts.reducer'
import { State as AlertsState } from './alerts/alerts.types'
import auth from './auth/auth.reducer'
import { State as AuthState } from './auth/auth.types'
import history from './history'
import newsletter from './newsletter/newsletter.reducer'
import { State as NewsletterState } from './newsletter/newsletter.types'
import settings from './settings/settings.reducer'
import { State as SettingsState } from './settings/settings.types'

export type AppState = {
  alerts: AlertsState
  auth: AuthState
  newsletter: NewsletterState
  settings: SettingsState
}

const reducers = combineReducers({
  alerts,
  auth,
  newsletter,
  settings,
  router: connectRouter(history),
})

export default reducers
