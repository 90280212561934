import React from 'react'
import Routes from '../../types/Routes'
import * as UserRole from '../../types/UserRole'

export const links = Object.freeze({
  adminEmptyPage: {
    name: 'Admin empty page',
    path: '/admin/admin-empty-page',
    exact: true,
    component: React.lazy(() => import('../../pages/Admin/AdminEmptyPage')),
  },
})

const routes: Routes = Object.keys(links)
  .map(key => links[key as keyof typeof links])
  .map(route => ({
    ...route,
    roles: [UserRole.ROLE_ADMIN],
    onlyAuth: true,
    onlyNotAuth: false,
  }))

export default routes
