import { enUS, Localization, plPL } from '@material-ui/core/locale'
import enMessages from '../translations/en.json'
import plMessages from '../translations/pl.json'

export const DEFAULT_FULL_ISO_CODE = 'pl-PL'
export const DEFAULT_ISO_CODE = 'pl'

export type FullIsoCode = typeof DEFAULT_FULL_ISO_CODE | 'pl-PL' | 'en-US'
export type IsoCode2 = typeof DEFAULT_ISO_CODE | 'pl' | 'en'

export type Type = {
  messages: Record<string, string>
  muiLocale: Localization
  isoCode: IsoCode2
  fullIsoCode: FullIsoCode
  name: string
  flag: string
}

const locale: Record<FullIsoCode, Type> = Object.freeze({
  'pl-PL': {
    messages: {
      ...plMessages,
    },
    muiLocale: plPL,
    isoCode: 'pl',
    fullIsoCode: 'pl-PL',
    name: 'Polish',
    flag: 'pl',
  },
  'en-US': {
    messages: {
      ...enMessages,
    },
    muiLocale: enUS,
    isoCode: 'en',
    fullIsoCode: 'en-US',
    name: 'English',
    flag: 'us',
  },
})

export default locale
