import { routerMiddleware } from 'connected-react-router'
import { Action as ReduxAction, applyMiddleware, compose, createStore, Dispatch as ReduxDispatch, Store as ReduxStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk'
import history from './history'
import rootReducer, { AppState } from './reducers'
import isCsr from '../utils/isCsr'

export type Store = ReduxStore<AppState, ReduxAction> & {
  dispatch: ReduxDispatch
  __PERSISTOR: Persistor
}

const configureStore = (initialState?: AppState): Store => {
  const middleware = [thunk, routerMiddleware(history)]
  const enhancers = []
  const windowIfDefined = isCsr ? (window as any) : null

  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())
  }

  const persistConfig = {
    key: 'PANEL',
    storage,
  }

  const reducers = isCsr ? persistReducer({ ...persistConfig }, rootReducer) : rootReducer
  const store = createStore(reducers as typeof rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers))

  return {
    ...store,
    __PERSISTOR: persistStore(store),
  }
}

const store = configureStore()
export default store
