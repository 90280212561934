import ReduxAction from '../../types/ReduxAction'
import User from '../../types/User'
import { CountryType } from '../../components/UI/NumberPrefixField/Countries'

export enum Action {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_ACTIVATION = 'SIGN_UP_ACTIVATION',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export type State = {
  token?: string
  user?: User
  smsCode?: string
}

export type SignUpAction = ReduxAction<typeof Action.SIGN_UP, SignUpResult>
export type SignUpActivationAction = ReduxAction<typeof Action.SIGN_UP_ACTIVATION, SignUpActivationResult>
export type SignInAction = ReduxAction<typeof Action.SIGN_IN, SignInResult>
export type SignOutAction = ReduxAction<typeof Action.SIGN_OUT>
export type RefreshTokenAction = ReduxAction<typeof Action.UPDATE_TOKEN, RefreshTokenResult>
export type ForgotPasswordAction = ReduxAction<typeof Action.FORGOT_PASSWORD, ResetPasswordResult>
export type ChangePasswordAction = ReduxAction<typeof Action.CHANGE_PASSWORD, ChangePasswordResult>
export type ActionTypes =
  | SignUpAction
  | SignUpActivationAction
  | SignInAction
  | SignOutAction
  | RefreshTokenAction
  | ForgotPasswordAction
  | ChangePasswordAction

export type SignUpPayload = {
  email: string
  password: string
  repeatedPassword: string
  tos: boolean
}

export type SignUpResult = {
  token: string
  user: User
}

export type SignInPayload = {
  username: string
  password: string
  country: CountryType
  prefix: string
  rememberMe: boolean
}

export type SignInResult = {
  token: string
  user: User
}

export type SignOutPayload = {}

export type SignOutResult = undefined

export type RefreshTokenPayload = {}

export type RefreshTokenResult = {
  token: string
  user: User
}

export type ResetPasswordPayload = {
  phone: string
  country: CountryType
  prefix: string
}

export type ResetPasswordResult = undefined

export type ChangePasswordPayload = {
  code: string
  newPassword: string
  repeatedPassword: string
}

export type ChangePasswordResult = {
  token: string
  user: User
}

export type SignUpGuestPayload = {
  firstName: string
  phone: string
  agreement1: boolean
  agreement2: boolean
  agreement3: boolean
}

export type SignUpGuestResult = {}

export type SignUpActivationPayload = {
  phone: string
  code: string
  agreement: boolean
  prefix: string
  country?: CountryType
}

export type SignUpActivationResult = {
  token: string
  user: User
  smsCode: string
}
