import { makeStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import { orange } from '@material-ui/core/colors'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Fonts, ThemeMode } from '../../../../../_crema/shared/constants/AppEnums'
import AppContext, { Type as AppContextType } from '../../../../../contexts/AppContext'
import useAuth from '../../../../../hooks/useAuth'
// import { useHistory } from 'react-router-dom'
// import { links } from '../../../../../config/routes'

const useStyles = makeStyles(theme => {
  return {
    crUserInfo: {
      backgroundColor: theme.palette.secondary.light,
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.EXTRA_BOLD,
      color: (props: { themeMode: ThemeMode }) => props.themeMode !== ThemeMode.DARK ? '#282828' : 'white',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
  }
})

const UserInfo: React.FC<{}> = () => {
  // const history = useHistory()
  const { themeMode } = useContext<AppContextType>(AppContext)
  const { user, signOut } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const handleAccountClick = () => {
  //   setAnchorEl(null)
  //   history.push(links.userAccount.path)
  // }

  const handleSignOutClick = () => {
    setAnchorEl(null)

    if (user) {
      signOut()
    }
  }

  const getUserAvatar = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase()
    }

    if (user && user.name) {
      return user.name.charAt(0).toUpperCase()
    }

    if (user && user.email) {
      return user.email.charAt(0).toUpperCase()
    }
  }

  const classes = useStyles({ themeMode })

  return (
    <Box px={{ xs: 4, xl: 7 }} className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display="flex" alignItems="center">
        {user && user.photoUrl ? (
          <Avatar src={user.photoUrl} />
        ) : (
          <Avatar>{getUserAvatar()}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mb={0} className={clsx(classes.userName)}>
              {user && (user.displayName ? user.displayName : user.name ? user.name : user.email ? user.email : 'Account')}
            </Box>
            <Box ml={3} className={classes.pointer} color={themeMode !== 'light' ? '#282828' : 'white'}>
              <ExpandMoreIcon onClick={handleClick} />
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {/* <MenuItem onClick={handleAccountClick}>
                  <FormattedMessage id="sidebar.learning.account" defaultMessage="Konto" />
                </MenuItem> */}
                <MenuItem onClick={handleSignOutClick}>
                  <FormattedMessage id="auth.signOut" defaultMessage="Wyloguj" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {/* <Box className={classes.designation}>System Manager4</Box> */}
        </Box>
      </Box>
    </Box>
  )
}

export default UserInfo
