import { FormikHelpers } from 'formik'
import { AxiosError } from './axios'

type OnSuccess = () => Promise<void>
type OnError = (error: AxiosError) => Promise<void>

const submitForm = async <T>(formikBag: FormikHelpers<T>, onSuccess?: OnSuccess, onError?: OnError, resetForm = true): Promise<void> => {
  try {
    formikBag.setSubmitting(true)
    onSuccess && (await onSuccess())
    resetForm && formikBag.setSubmitting(false)
    resetForm && formikBag.resetForm()
  } catch (error) {
    formikBag.setSubmitting(false)
    onError && (await onError(error))
  }
}

export default submitForm
