import MomentUtils from '@date-io/moment'
import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { InitialType, TypesType, useUrlSearchParams } from 'use-url-search-params'
import { useBreakPointDown } from '../../../_crema/@crema/utility/Utils'
import { NavStyle, ThemeMode, ThemeStyle } from '../../../_crema/shared/constants/AppEnums'
import locales from '../../../config/locales'
import AppContext, { Type as AppContextType } from '../../../contexts/AppContext'
import { AppState } from '../../../services/reducers'
import Children from '../../../types/Children'

type Props = {
  children: Children
}

const ThemeProvider = (props: Props) => {
  const { language } = useSelector<AppState, AppState['settings']>(({ settings }) => settings)
  const { theme, themeStyle, themeMode, isRTL, setRTL, updateThemeMode, changeNavStyle, updateThemeStyle, updateTheme } = useContext<
    AppContextType
  >(AppContext)
  const { muiLocale } = locales[language.fullIsoCode]
  const isBelowMd = useBreakPointDown('md')
  const initailValue: InitialType = {}
  const types: TypesType = {}
  const [params] = useUrlSearchParams(initailValue, types)

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_mode) {
        updateThemeMode!(params.theme_mode as ThemeMode)
      }
    }
    updateQuerySetting()
  }, [params.theme_mode, updateThemeMode])

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.is_rtl) {
        setRTL!(params.is_rtl as boolean)
      }
      if (params.is_rtl || isRTL) {
        document.body.setAttribute('dir', 'rtl')
      } else {
        document.body.setAttribute('dir', 'ltr')
      }
    }
    updateQuerySetting()
  }, [isRTL, params.is_rtl, setRTL])

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.nav_style) {
        changeNavStyle!(params.nav_style as NavStyle)
      }
    }
    updateQuerySetting()
  }, [changeNavStyle, params.nav_style])

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_style) {
        if (params.theme_style === ThemeStyle.MODERN) {
          if (isBelowMd) {
            theme.overrides.MuiCard.root.borderRadius = 20
            theme.overrides.MuiToggleButton.root.borderRadius = 20
          } else {
            theme.overrides.MuiCard.root.borderRadius = 30
            theme.overrides.MuiToggleButton.root.borderRadius = 30
          }
          theme.overrides.MuiButton.root.borderRadius = 30
          theme.overrides.MuiCardLg.root.borderRadius = 50
        } else {
          theme.overrides.MuiCard.root.borderRadius = 4
          theme.overrides.MuiToggleButton.root.borderRadius = 4
          theme.overrides.MuiButton.root.borderRadius = 4
          theme.overrides.MuiCardLg.root.borderRadius = 4
        }
        updateTheme!(theme)
        if (params.theme_style === ThemeStyle.MODERN || params.theme_style === ThemeStyle.STANDARD) {
          updateThemeStyle!(params.theme_style as ThemeStyle)
        }
      }
    }
    updateQuerySetting()
  }, [params.theme_style, theme, isBelowMd, updateTheme, updateThemeStyle])

  return (
    <div className={classNames(`theme--${themeMode}`, `layout--${themeStyle}`)}>
      <MuiThemeProvider theme={responsiveFontSizes(createTheme(theme, muiLocale))}>
        <MuiPickersUtilsProvider utils={MomentUtils}>{props.children}</MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default React.memo(ThemeProvider)
