import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { createSuccessAction } from '../services/alerts/alerts.actions'
import { ActionTypes as AlertActionTypes } from '../services/alerts/alerts.types'
import { signInAction, signOutAction, signUpAction, signUpActivationAction } from '../services/auth/auth.actions'
import * as authApi from '../services/auth/auth.api'
import { AppState } from '../services/reducers'
import User from '../types/User'
import {
  ActionTypes,
  ChangePasswordPayload,
  ChangePasswordResult,
  ResetPasswordPayload,
  ResetPasswordResult,
  SignInPayload,
  SignInResult,
  SignOutResult,
  SignUpActivationPayload,
  SignUpActivationResult,
  SignUpGuestPayload,
  SignUpGuestResult,
  SignUpPayload,
  SignUpResult,
} from '../services/auth/auth.types'

const useAuth = () => {
  const { formatMessage } = useIntl()
  const dispatch: Dispatch<ActionTypes | AlertActionTypes> = useDispatch()
  const [loading, setLoading] = useState(false)
  let isMounted: boolean

  const signUp = async (payload: SignUpPayload): Promise<SignUpResult> => {
    let response: SignUpResult

    try {
      setLoading(true)
      response = await authApi.signUp(payload)
      dispatch(signUpAction(response))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const signUpGuest = async (payload: SignUpGuestPayload): Promise<SignUpGuestResult> => {
    let response: SignUpGuestResult

    try {
      setLoading(true)
      response = await authApi.signUpGuest(payload)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const resetPassord = async (payload: ResetPasswordPayload): Promise<ResetPasswordResult> => {
    let response: ResetPasswordResult

    try {
      setLoading(true)
      response = await authApi.resetPassord(payload)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const changePassord = async (payload: ChangePasswordPayload): Promise<ChangePasswordResult> => {
    let response: ChangePasswordResult

    try {
      setLoading(true)
      response = await authApi.changePassword(payload)
      dispatch(signInAction(response))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true

    return () => {
      isMounted = false
    }
  }, [])

  const activate = async (payload: Omit<SignUpActivationPayload, 'agreement'>): Promise<SignUpActivationResult> => {
    let response: SignUpActivationResult
    payload.prefix = user?.prefix || payload.country?.phone || ''

    try {
      setLoading(true)
      response = await authApi.activate(payload)
      dispatch(signUpActivationAction(response))
      payload.country && localStorage.setItem('country', payload.country.code)
      isMounted && setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const signIn = async (payload: SignInPayload): Promise<SignInResult> => {
    let response: SignInResult

    try {
      setLoading(true)
      response = await authApi.signIn(payload)
      dispatch(signInAction(response))
      localStorage.setItem('country', payload.country.code)
      isMounted && setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const signOut = async (): Promise<SignOutResult> => {
    let response: SignOutResult

    try {
      setLoading(true)
      response = await authApi.signOut()
      dispatch(signOutAction(response))
      dispatch(
        createSuccessAction(
          formatMessage({
            id: 'auth.signedOutSuccess',
            defaultMessage: 'Zostałeś pomyślnie wylogowany',
          }),
        ),
      )
      const modal = localStorage.getItem('modal')
      if (modal) {
        const modalExpirationDate = new Date(JSON.parse(modal).expirationDate)
        if (modalExpirationDate < new Date()) {
          localStorage.removeItem('modal')
        }
      }
    } catch (e) {
      setLoading(false)
      throw e
    }

    return response
  }

  const refreshToken = async (): Promise<SignInResult> => {
    const response: SignInResult = await authApi.refreshToken()
    dispatch(signInAction(response))
    return response
  }

  const isSignedIn = useSelector((state: AppState): boolean => !!(!loading && state.auth.token && state.auth.user?.id))

  const user = useSelector((state: AppState): User | null => (loading ? null : state.auth.user || null))

  const token = useSelector((state: AppState): string | null => (loading ? null : state.auth.token || null))

  return {
    signUp,
    signUpGuest,
    resetPassord,
    changePassord,
    activate,
    signIn,
    signOut,
    refreshToken,
    isSignedIn,
    user,
    token,
    loading,
  }
}

export default useAuth
