import React from 'react'
import Routes from '../../types/Routes'
import * as UserRole from '../../types/UserRole'

export const links = Object.freeze({
  authSignIn: {
    name: 'Sign In',
    path: '/auth/sign-in',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignInPage')),
  },
  authSignUp: {
    name: 'Sign Up',
    path: '/auth/sign-up',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignUpPage')),
  },
  authSignUpGuest: {
    name: 'Sign Up',
    path: '/auth/sign-up/guest',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignUpGuestPage')),
  },
  authSignOut: {
    name: 'Sign Out',
    path: '/auth/sign-out',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignOutPage')),
  },
  authSignUpConfirmation: {
    name: 'Confirmation',
    path: '/auth/sign-up/confirmation',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignUpConfirmationPage')),
  },
  authSignUpActivation: {
    name: 'SignUpActivationPage',
    path: '/activation',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/SignUpActivationPage')),
  },
  authForgotPassword: {
    name: 'Forgot Password',
    path: '/auth/forgot-password',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/ForgotPasswordPage')),
  },
  authChangePassword: {
    name: 'Change Password',
    path: '/auth/change-password',
    exact: true,
    component: React.lazy(() => import('../../pages/Auth/ChangePasswordPage')),
  },
  privacyPolicy: {
    name: 'privacy policy',
    path: '/privacy-policy',
    exact: true,
    component: React.lazy(() => import('../../pages/PrivacyPolicyPage')),
  },
})

const routes: Routes = [
  links.authSignIn,
  links.authSignUp,
  links.authSignUpGuest,
  links.authSignUpConfirmation,
  links.authSignUpActivation,
  links.authForgotPassword,
].map(route => ({
  ...route,
  roles: [UserRole.IS_AUTHENTICATED_ANONYMOUSLY],
  onlyAuth: false,
  onlyNotAuth: true,
}))

routes.push(
  ...[
    {
      ...links.authChangePassword,
      roles: [UserRole.IS_AUTHENTICATED_FULLY, UserRole.IS_AUTHENTICATED_ANONYMOUSLY],
      onlyAuth: true,
      onlyNotAuth: false,
    },
    {
      ...links.privacyPolicy,
      roles: [UserRole.IS_AUTHENTICATED_FULLY, UserRole.IS_AUTHENTICATED_ANONYMOUSLY],
      onlyAuth: false,
      onlyNotAuth: false,
    },
  ],
)

routes.push({
  ...links.authSignOut,
  roles: [UserRole.IS_AUTHENTICATED_FULLY],
  onlyAuth: true,
  onlyNotAuth: false,
})

export default routes
