import CssBaseline from '@material-ui/core/CssBaseline'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { Provider } from 'react-redux'
import AppProvider from './AppProvider'
import AuthRouter from './AuthRouter/AuthRouter'
import DebugRouter from './DebugRouter'
import LayoutProvider from './LayoutProvider'
import LocaleProvider from './LocaleProvider'
import './styles/styles.scss'
import ThemeProvider from './ThemeProvider'
import CremaStyleProvider from '../../_crema/@crema/utility/CremaStyleProvider'
import history from '../../services/history'
import store from '../../services/store'

const App = () => (
  <AppProvider>
    <Provider store={store}>
      <ThemeProvider>
        <CremaStyleProvider>
          <LocaleProvider>
            <ConnectedRouter history={history}>
              <DebugRouter>
                <AuthRouter>
                  <CssBaseline />
                  <LayoutProvider />
                </AuthRouter>
              </DebugRouter>
            </ConnectedRouter>
          </LocaleProvider>
        </CremaStyleProvider>
      </ThemeProvider>
    </Provider>
  </AppProvider>
)

export default App
