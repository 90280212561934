import ReduxAction from '../../types/ReduxAction'

export enum Action {
  CREATE_MESSAGE = 'CREATE_MESSAGE',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
  DELETE_MESSAGES = 'DELETE_MESSAGES',
}

export type Message = {
  id: string
  type: 'success' | 'info' | 'warning' | 'error'
  content: string
  createdAt: Date
}

export type State = {
  messages: Message[]
}

export type CreateMessageAction = ReduxAction<typeof Action.CREATE_MESSAGE, Message>
export type DeleteMessageAction = ReduxAction<typeof Action.DELETE_MESSAGE, { id: string }>
export type DeleteMessagesAction = ReduxAction<typeof Action.DELETE_MESSAGES, undefined>
export type ActionTypes = CreateMessageAction | DeleteMessageAction | DeleteMessagesAction
