import { useHistory } from 'react-router-dom'

type Props = {
  children: any
}

const DebugRouter = (props: Props) => {
  const history = useHistory()

  history.listen((location, action) => {
    // console.log({
    //   action,
    //   location,
    // })
  })

  return props.children
}

export default DebugRouter
