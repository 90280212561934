import React from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RouteTransition } from '../../../../../_crema/shared/constants/AppEnums'
import AppContext, { Type as AppContextType } from '../../../../../contexts/AppContext'

interface TransitionWrapperProps {
  children: any
}

const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ children }) => {
  const { rtAnim } = React.useContext<AppContextType>(AppContext)
  const location = useLocation()

  if (rtAnim === RouteTransition.NONE) {
    return <>{children}</>
  }

  return (
    <TransitionGroup appear enter exit>
      <CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames={rtAnim}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default TransitionWrapper
