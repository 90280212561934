import * as yup from 'yup'
import 'yup-phone'
import equalTo from '../assert/equalTo'
import uuid from '../assert/uuid'

yup.addMethod<yup.StringSchema>(yup.string, 'uuid', uuid)
yup.addMethod<yup.StringSchema>(yup.string, 'equalTo', equalTo)

declare module 'yup' {
  interface StringSchema {
    uuid(message?: string): StringSchema
    equalTo(field2: string, value2: string, message?: string): StringSchema
  }
}

export default yup
