export const PROPERTY_PATH = '__global'
export const STATUS_CODE = 400
export const STATUS_TEXT = 'Bad Request'

type Error = {
  propertyPath: string
  message: string
}

export type Errors = {
  statusCode: number
  message: string
  errors: Array<Error>
}

export const createErrors = (message?: string, statusCode?: number, errors?: Array<Error>): Errors => ({
  statusCode: statusCode || STATUS_CODE,
  message: message || STATUS_TEXT,
  errors: errors || [
    {
      propertyPath: PROPERTY_PATH,
      message: message || STATUS_TEXT,
    },
  ],
})

export default Errors
