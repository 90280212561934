import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import VerticalCollapse from './VerticalCollapse'
import VerticalItem from './VerticalItem'
import VerticalNavGroup from './VerticalNavGroup'
import { NavItemProps as MenuItem } from '../../../../../../_crema/modules/routesConfig'
import useAuth from '../../../../../../hooks/useAuth'
import PackageActivationModal from '../../../../../Modal/PackageActivationModal'

type Props = {
  menu: MenuItem[]
}

const Navigation = (props: Props) => {
  const { user } = useAuth()
  const [open, setOpen] = React.useState<boolean>(false)

  const handleModalClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown', updated: boolean) => {
    setOpen(false)

    if (updated && window) {
      window.location.reload()
    }
  }

  const handleActivateClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true)
  }

  return (
    <>
      <List>
        {user?.packagesForActivation && (
          <Box display="flex" mt={3} mb={3} justifyContent="center" alignItems="center">
            <Button variant="contained" color="secondary" type="submit" onClick={e => handleActivateClick(e)}>
              <FormattedMessage id="course.activeButton" defaultMessage="Aktywuj pakiety" />
            </Button>
          </Box>
        )}
        {props.menu.map(item => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}
            {item.type === 'collapse' && <VerticalCollapse item={item} level={0} />}
            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </List>
      <PackageActivationModal open={open} onClose={handleModalClose} />
    </>
  )
}

export default Navigation
