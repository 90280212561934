import React from 'react'
import Box from '@material-ui/core/Box'
import styles from './AuthLayout.module.scss'
import ContentView from '../shared/ContentView'

const AuthLayout = (props: { branding: string }) => {
  const brand = props.branding
  return (
    <Box className={styles[`${brand}-container`]}>
      <Box className={styles['container__box']}>
        <ContentView />
      </Box>
    </Box>
  )
}

export default AuthLayout
