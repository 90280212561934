import { v4 as createUuid } from 'uuid'
import * as UserRole from './UserRole'

type User = {
  id: string
  name: string
  displayName: string | null
  photoUrl: string | null
  packagesForActivation: boolean
  email: string
  phone?: string
  roles: Array<{
    name: keyof typeof UserRole
    expiredAt: Date | null
  }>
  createdAt: Date
  lastLoggedIn: Date | null
  active: boolean
  certificates: boolean
  prefix?: string
}

export const createEmptyUser = (): User => ({
  id: createUuid(),
  name: '',
  displayName: null,
  photoUrl: null,
  packagesForActivation: false,
  email: '',
  roles: [
    {
      name: UserRole.IS_AUTHENTICATED_ANONYMOUSLY,
      expiredAt: null,
    },
  ],
  createdAt: new Date(),
  lastLoggedIn: null,
  active: false,
  certificates: false,
})

export const hasRole = (user: User, roleName: string): boolean =>
  user.roles.find(role => role.name === roleName && (role.expiredAt === null || role.expiredAt > new Date())) !== undefined

export const hasAccess = (inUser?: User | null, roles?: string[]): boolean => {
  const user = inUser || createEmptyUser()

  if (!user || !roles) {
    return false
  }

  return (
    isSuperAdmin(user) ||
    user.roles.find(userRole => (userRole.expiredAt === null || userRole.expiredAt < new Date()) && roles.indexOf(userRole.name) >= 0) !==
      undefined
  )
}

export const isAdmin = (user: User): boolean => hasRole(user, UserRole.ROLE_ADMIN) || hasRole(user, UserRole.ROLE_SUPER_ADMIN)

export const isSuperAdmin = (user: User): boolean => hasRole(user, UserRole.ROLE_SUPER_ADMIN)

export default User
