import { Action, ChangeLanguageAction, SetInitialPathAction, ToggleNavCollapsed } from './settings.types'
import locales, { FullIsoCode } from '../../config/locales'

export const toggleNavCollapsedAction = (): ToggleNavCollapsed => ({
  type: Action.TOGGLE_NAV_COLLAPSED,
  data: undefined,
})

export const setInitialPathAction = (initialPath: string | undefined): SetInitialPathAction => ({
  type: Action.SET_INITIAL_PATH,
  data: {
    initialPath,
  },
})

export const changeLanguageAction = (isoCode: FullIsoCode): ChangeLanguageAction => ({
  type: Action.CHANGE_LANGUAGE,
  data: {
    isoCode: locales[isoCode].isoCode,
    fullIsoCode: locales[isoCode].fullIsoCode,
    name: locales[isoCode].name,
    flag: locales[isoCode].flag,
  },
})
