import { NavItemProps as BaseNavItemProps } from '../../_crema/modules/routesConfig'
import User from '../../types/User'
import { links, routesByPath } from '../routes'

type NavItemProps = BaseNavItemProps

const getMainManu = (user: User | null, brand?: string | 'poliglotos'): NavItemProps[] => {
  const menuItems: NavItemProps[] = [
    {
      id: 'my-courses',
      title: 'Moje kursy',
      messageId: 'sidebar.learning.my-courses',
      type: 'item',
      icon: 'local_library',
      url: links.userLanguages.path,
      auth: routesByPath[links.userLanguages.path].roles,
      exact: links.userLanguages.exact,
    },
    {
      id: 'my-ebooks',
      title: 'Moje ebooki',
      messageId: 'sidebar.learning.my-ebooks',
      type: 'item',
      icon: 'menu_book',
      url: links.userEbooks.path,
      auth: routesByPath[links.userEbooks.path].roles,
      exact: links.userEbooks.exact,
    },
    {
      id: 'stats',
      title: 'Statystyki',
      messageId: 'sidebar.learning.stats',
      type: 'item',
      icon: 'insights',
      url: links.userStats.path,
      auth: routesByPath[links.userStats.path].roles,
      exact: links.userStats.exact,
    },
    {
      id: 'account',
      title: 'Konto',
      messageId: 'sidebar.learning.account',
      type: 'item',
      icon: 'account_circle',
      url: links.userAccount.path,
      auth: routesByPath[links.userAccount.path].roles,
      exact: links.userAccount.exact,
    },
    {
      id: 'tos',
      title: 'Regulamin aplikacji',
      messageId: 'sidebar.learning.tos',
      type: 'item',
      icon: 'assignment',
      url: `${process.env.PUBLIC_URL}/files/regulamin-aplikacji-${brand}.pdf`,
      auth: routesByPath[links.userAccount.path].roles,
      exact: links.userAccount.exact,
      target: '_blank',
    },
    {
      id: 'store-policy',
      title: 'Regulamin sklepu',
      messageId: 'sidebar.learning.store-policy',
      type: 'item',
      icon: 'assignment',
      url: `${process.env.PUBLIC_URL}/files/regulamin-sklepu-${brand}.pdf`,
      auth: routesByPath[links.userAccount.path].roles,
      exact: links.userAccount.exact,
      target: '_blank',
    },
  ]

  if (user?.certificates) {
    menuItems.splice(1, 0, {
      id: 'certificates',
      title: 'Certyfikaty',
      messageId: 'sidebar.learning.my-certificates',
      type: 'item',
      icon: 'verified_user',
      url: links.userCertificates.path,
      auth: routesByPath[links.userCertificates.path].roles,
      exact: links.userCertificates.exact,
    })
  }

  const menu: NavItemProps[] = [
    {
      id: 'app',
      title: 'Nauka',
      messageId: 'sidebar.learning',
      type: 'group',
      auth: [
        ...routesByPath[links.userLanguages.path].roles,
        ...routesByPath[links.userStats.path].roles,
        ...routesByPath[links.userAccount.path].roles,
      ],
      children: menuItems,
    },
  ]

  return menu
}

export default getMainManu
