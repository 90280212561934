import axios, { AxiosError as _AxiosError, AxiosResponse as _AxiosResponse } from './axios'
import './mock'
// import '../../services/courses/courses.mock'
// import '../../services/auth/auth.mock'
// import '../../services/newsletter/newsletter.mock'
// import '../../services/user/user.mock'

export type AxiosResponse = _AxiosResponse
export type AxiosError = _AxiosError

export default axios
